.perform-card {
  // width: 70rem;
  padding-top: 10px;

  height: calc(100vh - 250px) !important;
  // overflow: scroll;
  overflow-x: hidden;
  font-size: 18px !important;
  color: var(--hfont);
  .badge {
    color: white !important;
    background-color: var(--mcolor);
    padding: 10px 18px;
    font-size: 15px;
    span {
      color: white !important;
    }
  }
  .label {
    color: var(--bfont);
  }
  h5 {
    color: var(--hfont);
  }
  span {
    color: var(--bfont);
  }
  li,
  p {
    color: var(--bfont);
  }
  .list-type-none {
    list-style: none;
  }
  .card {
    border: 1px solid;
    border-color: var(--border1);
  }
  .scroll-area {
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px; /* Adjust the width as needed */
      background-color: transparent; /* Hide the scrollbar background */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd; /* Customize the color of the scrollbar thumb */
    }
  }
  .social-url {
    text-align: right;
  }
  .esai {
    background-color: #44b996;
    .score {
      font-size: 4rem;
    }
  }
}

// candidate profile
.profile_section-title {
  color: var(--hfont) !important;
  font-size: 22px !important;
  font-weight: bold !important;
}

.profile_subsection-title {
  color: var(--hfont) !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.profile_subsection-dim_title {
  color: #485166 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: "Univers Condensed Medium" !important;
}

.profile_subsection-subtitle {
  color: #56627c !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Univers Condensed Medium" !important;
  white-space: pre-wrap;
}

.profile_subsection-body {
  color: #727fa2 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  list-style: none;
  white-space: pre-wrap;
  // font-family: "Univers Condensed Regular Type" !important;
}
// .user-profile {
//   img {
//     // border: 3px solid var(--mcolor-dark);
//     box-shadow: 0 0 8px rgba(68, 51, 174, 0.9), 0 0 30px rgba(68, 51, 174, 0.5);
//     border-radius: 50%;
//   }
// }
// .social-web-screen {
//   @media (max-width: 1220px) {
//     display: none;
//   }
// }
.social-mobile-screen {
  @media (max-width: 991px) {
    width: 100%;
  }
}

.note-hover:hover {
  background-color: #e1def5;
  transition: 0.2s;

  .scroller {
    scrollbar-color: var(--mcolor) #d0caf3;
    scrollbar-width: thin;
  }
}
.note-selected {
  background-color: #d0caf3;
}

.note-icon {
  font-size: 22px;
  cursor: pointer;
}

.icon-hover:hover {
  fill: #485166;
}

.take-note-input {
  color: #485166;
}
.take-note-input::placeholder {
  font-weight: 400;
  color: #969494;
}

.btn-dark {
  color: var(--hfont) !important;
}

.match-modal-body {
  height: calc(100vh - 180px) !important;
  overflow: scroll;
  overflow-x: hidden;
}
